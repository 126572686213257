<template>
  <b-card>
    <FormBuilder
      label="Category"
      create
      :fields="fields"
      :loading-submit="$store.state.category.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'longDescription',
          label: 'Long Description',
          type: 'textarea',
          maxLength: 8,
        },
        {
          key: 'sellerDescription',
          label: 'Seller Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'image',
          label: 'Image',
          type: 'image',
          required: true,
        },
        {
          key: 'homepageBanner',
          label: 'Image',
          type: 'image',
          required: true,
        },
        {
          key: 'suggestKeywords',
          label: 'Suggestion Words',
          type: 'multiple',
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('category/create', {
        ...data,
        roles: ['customer'],
      })
        .then(callback)
    },
  },
}
</script>

    <style scoped>

    </style>
